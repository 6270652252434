/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    em: "em"
  }, _provideComponents(), props.components), {VideoIframe} = _components;
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Excitement at Mt Earverest! Someone snapped the moon! Young people can now play the online game \"Blackout at Mt. Earverest\" on the Roblox game platform and put their hearing to the test in a playful way."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "blackout-at-mt-earverest",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#blackout-at-mt-earverest",
    "aria-label": "blackout at mt earverest permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Blackout at Mt Earverest"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the online game Blackout at Mt. Earverest, Roblox players must help the inhabitants of the game world, who now live in the dark, to turn on the big moon switch at the top again. On the way to the goal, you have to overcome canyons, conquer a waterfall and build a tower to the top. A rabbit, a fish and a giraffe help them to find their way around in the semi-darkness of the game world with their sense of hearing. What they don't know: It's not just about reaching the mountain top, but also about testing your own hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "\"Blackout at Mt. Earverest\" can be reached via the link ", React.createElement("a", {
    href: "https://www.roblox.com/games/12864815372/Blackout-at-Mt-Earverest",
    target: "_blank"
  }, "www.erverest.com")), "\n", React.createElement(VideoIframe, {
    title: "Blackout at Mt. Earverest - Roblox Hearing Test Game by audibene/hear.com/Grabarz",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/bOHyzWiPd-4"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-advice-from-hearcom-hearing-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-advice-from-hearcom-hearing-experts",
    "aria-label": "get advice from hearcom hearing experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get advice from hear.com hearing experts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Those who do poorly in this game can be affected by the onset of hearing loss. A free initial consultation from a ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " hearing expert for children and young people brings security and clarification. A check-up should then be carried out by an ear, nose and throat specialist."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing experts are available on this number for free advice, contact us at: ", React.createElement(_components.a, {
    href: "tel:305-551-2085",
    className: "c-md-a"
  }, "305-551-2085")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "over-1-billion-young-people-are-at-risk-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#over-1-billion-young-people-are-at-risk-of-hearing-loss",
    "aria-label": "over 1 billion young people are at risk of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Over 1 billion young people are at risk of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement("a", {
    href: "https://gh.bmj.com/content/7/11/e010501",
    target: "_blank"
  }, "According to a WHO study"), ", one billion young people are unknowingly at risk of hearing loss. Untreated hearing loss not only affects self-confidence, it can also ", React.createElement("a", {
    href: "https://hearinghealthfoundation.org/blogs/psychology-effects-of-hearing-loss-in-teens",
    target: "_blank"
  }, "lead to a delay in personal development"), " and thus to academic disadvantage. With the game launch of \"Blackout at Mt. Earverest\" an innovative and low-threshold way is taken to provide enlightenment. The game is aimed at young people between the ages of 12 and 16. You are in a critical phase of the decade-long diagnostic gap between infancy and retirement age."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While hear.com primarily focuses on people with age-related hearing loss from the age of 55, more than ten years of market experience has shown that young people still show little awareness of their own well-being and that a possible hearing impairment can therefore only be recognized and treated to a limited extent."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So you go where the young target group is: on the game platform Roblox, which is ", React.createElement("a", {
    href: "https://ir.roblox.com/news/news-details/2023/Roblox-Reports-February-2023-Key-Metrics/default.aspx",
    target: "_blank"
  }, "used by 67.3 million active players"), " for over 4.6 billion hours every month. Players who reach the goal - the top of Mt. Earverest - will not only be informed about the score at the end, but also that their frequency and stereo hearing as well as speech understanding were tested in passing. Without pointing fingers, they are advised to pay attention to their own hearing and, if necessary, to have them examined."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Paul Crusius, founder and managing director of hear.com: “", React.createElement(_components.em, null, "With the increasing use of wireless headphones and listening to loud music, children are endangering their hearing and are at risk of suffering from hearing loss at an early age. That's why we want to use the game hearing test, which we developed in cooperation with Grabarz & Partner, to playfully sensitize young people between the ages of 12 and 16 to the topic of hearing health. The gaming platform Roblox offers a suitable and at the same time unusual environment to reach young people with our message of precaution."), "”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Since 2012, hear.com has been on the mission to make ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " fitting as easy, convenient and affordable as possible. In Europe, the world's leading online audiologist is known under the audibene brand, in North America and Asia the company, which has 1,500 employees, operates under the hear.com brand."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "audibene/hear.com developed and implemented the Roblox game together with the advertising agency Grabarz & Partner and Headraft, an agency for immersive communication also based in Hamburg."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-us-at-305-551-2085",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-us-at-305-551-2085",
    "aria-label": "contact us at 305 551 2085 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact us at: ", React.createElement(_components.a, {
    href: "tel:305-551-2085",
    className: "c-md-a"
  }, "305-551-2085")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
